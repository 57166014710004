<template>
  <svg
    width="152"
    height="145"
    viewBox="0 0 152 145"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.766 64.8252V139.183C72.766 141.122 72.7336 143.061 72.7336 145C71.7311 144.564 70.7447 144.128 69.7421 143.691C63.4357 140.912 57.1132 138.133 50.8068 135.354C43.1745 131.993 35.526 128.632 27.8936 125.271C21.2962 122.363 14.7149 119.455 8.11745 116.546C5.40085 115.351 2.74894 114.042 0 112.895V38.5526C0 36.6137 0.0161703 34.6748 0.0323406 32.7358C1.05106 33.1883 2.06979 33.6407 3.08851 34.0769C9.41106 36.8722 15.7498 39.6514 22.0723 42.4305C27.9583 45.0158 33.8443 47.6172 39.7302 50.2024C41.4766 50.978 43.223 51.7374 44.9855 52.513C51.5992 55.4214 58.2289 58.3459 64.8426 61.2543C67.4783 62.4177 70.1302 63.581 72.766 64.7444V64.8252Z"
      fill="#737373"
    />
    <path
      d="M79.2829 64.7606C80.7544 64.1142 82.2097 63.4679 83.665 62.8216C89.6642 60.1717 95.6957 57.538 101.695 54.8882C105.899 53.03 110.119 51.188 114.324 49.3299V68.5415C114.324 70.238 115.811 71.8538 117.558 71.773C119.304 71.6922 120.792 70.3511 120.792 68.5415V46.4861C121.924 45.9367 123.25 45.3874 124.495 44.8542C125.465 44.4341 126.419 43.9978 127.373 43.5777C133.971 40.6693 140.568 37.7609 147.165 34.8687C147.214 34.8525 147.246 34.8202 147.311 34.804C148.879 34.1093 150.545 33.4145 152 32.7358V104.299C152 107.191 151.968 110.099 151.984 112.992C149.898 113.913 147.828 114.834 145.742 115.755C139.436 118.534 133.114 121.313 126.807 124.092C119.175 127.453 111.542 130.814 103.894 134.174C97.2965 137.083 90.6991 139.991 84.1016 142.9C82.4846 143.611 80.8514 144.289 79.2344 144.984V73.4534C79.2344 73.0657 79.2344 72.6779 79.2344 72.2739C79.2505 69.7695 79.2829 67.265 79.2667 64.7606H79.2829Z"
      fill="#737373"
    />
    <path
      d="M75.9346 59.1539C73.8648 58.2491 71.8112 57.3443 69.7414 56.4233C63.435 53.6441 57.1125 50.865 50.8061 48.0858C43.1737 44.725 35.5252 41.3642 27.8929 38.0034C21.2954 35.095 14.698 32.1866 8.08438 29.2782C7.08182 28.8258 6.0631 28.3895 5.06055 27.9371C5.07672 27.9371 5.09289 27.9371 5.09289 27.9209C5.22225 27.8725 5.33544 27.824 5.4648 27.7755C11.7874 25.2872 18.0937 22.7828 24.4163 20.2945C28.9278 18.5171 33.4393 16.7398 37.9346 14.9624C40.3763 15.9642 42.8342 16.966 45.2759 17.9839C51.5984 20.5853 57.9048 23.1706 64.2274 25.772C71.8435 28.9066 79.4597 32.0412 87.0759 35.1596C93.6733 37.8741 100.287 40.5886 106.901 43.3031C107.774 43.6586 108.647 44.0302 109.504 44.4019C107.628 45.2259 105.752 46.05 103.893 46.874C97.2954 49.7824 90.698 52.6908 84.1005 55.5992C81.4001 56.7949 78.6512 57.9098 75.9508 59.1539H75.9346Z"
      fill="#737373"
    />
    <path
      d="M146.906 27.9691C146.518 28.1307 146.13 28.3084 145.742 28.4862C139.436 31.2653 133.113 34.0444 126.807 36.8236C123.767 38.1647 120.727 39.5058 117.687 40.8307C115.278 39.8451 112.884 38.8595 110.475 37.8738C104.152 35.2724 97.8461 32.671 91.5235 30.0858C83.9073 26.9512 76.2912 23.8166 68.675 20.6981C62.0614 17.9836 55.4639 15.2691 48.8503 12.5546C48.0903 12.2476 47.3303 11.9244 46.5703 11.6013C46.7967 11.5043 47.0231 11.4235 47.2495 11.3266C53.8793 8.70904 60.4929 6.10764 67.1227 3.49008C69.2895 2.63372 71.4563 1.77736 73.6231 0.920993C74.4154 0.613996 75.2239 0.323156 76.0163 0C78.3448 0.920993 80.6895 1.84199 83.018 2.76298C89.3405 5.25128 95.6469 7.73957 101.969 10.244C109.586 13.2494 117.202 16.2547 124.818 19.2601C131.448 21.8776 138.061 24.479 144.691 27.0966C145.435 27.3551 146.179 27.6621 146.906 27.9691Z"
      fill="#737373"
    />
  </svg>
</template>

<script>
export default {
    name: 'OrderBoxIcon',
}
</script>
