<template>
  <b-modal
    :id="modalId"
    centered
    hide-header
    hide-footer
    size="lg"
    @hide="clearContext"
  >
    <confetti-animation>
      <!-- body -->
      <div class="w-100 pb-2 font-inter">
        <div class="w-100">
          <feather-icon
            class="float-right m-50 color-neutral-black cursor-pointer"
            icon="XIcon"
            size="24"
            @click="$bvModal.hide(modalId)"
          />
        </div>
        <div class="w-100 d-flex justify-content-center text-center">
          <div class="w-100">
            <div class="mt-5 pt-2 mb-3">
              <order-box-icon />
            </div>
            <div v-if="isMultiple">
              <div class="modal-body-text">
                <div>
                  {{ modalContext.title }}
                </div>
                <div>
                  {{ orderIds.length }} orders are {{ modalContext.successfullyPlaced }}
                </div>
              </div>
              <div class="order-ids-container scroll-bar">
                <div
                  v-for="orderIdItem in orderIds"
                  :key="orderIdItem.orderId"
                  class="order-id-item"
                >
                  <div class="w-75">
                    <div class="mt-50 item-text">
                      Order {{ orderIdItem.orderSeqId }}
                    </div>
                    <div class="mt-50 w-75 skeleton-item" />
                    <div class="mt-50 w-50 skeleton-item" />
                  </div>
                  <div class="h-100 d-flex align-items-center w-25">
                    <b-button
                      variant="secondary-black"
                      class="mr-2 px-3 text-nowrap"
                      @click="onClickGoToOrder(orderIdItem.orderId)"
                    >
                      {{ modalContext.goToOrder }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <b-button
                  variant="outline-secondary-black"
                  class="mr-1"
                  @click="onClickGoToCheckout"
                >
                  {{ modalContext.placeAnotherOrder }}
                </b-button>
              </div>
            </div>
            <div v-else>
              <div class="modal-body-text">
                <div>
                  {{ modalContext.title }}
                </div>
                <div>
                  Order {{ orderIds[0].orderSeqId }} is {{ modalContext.successfullyPlaced }}
                </div>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <b-button
                  variant="outline-secondary-black"
                  class="mr-1"
                  @click="onClickGoToCheckout"
                >
                  {{ modalContext.placeAnotherOrder }}
                </b-button>
                <b-button
                  variant="secondary-black"
                  class="ml-1"
                  @click="onClickGoToOrders"
                >
                  {{ modalContext.goToOrders }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </confetti-animation>

  </b-modal>
</template>

<script>
import OrderBoxIcon from '@/assets/images/svg/OrderBoxIcon.vue'
import { BButton, VBModal } from 'bootstrap-vue'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import ConfettiAnimation from '../confetti-animation/ConfettiAnimation.vue'

export default {
  name: 'OrderSuccessModal',
  components: { OrderBoxIcon, BButton, ConfettiAnimation },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin],
  props: {
    modalId: {
      type: String,
      default() {
        return 'order_success_modal'
      },
    },
    orderIds: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      modalContext: {
        title: 'Congratulations!',
        placeAnotherOrder: 'Place another order',
        goToOrders: 'Go to Orders',
        goToOrder: 'Go to Order',
        successfullyPlaced: 'successfully placed!',
      },
    }
  },
  computed: {
    isMultiple() {
      return this.orderIds.length > 1
    },
  },
  mounted() {
    this.$bvModal.show(this.modalId)
  },
  methods: {
    clearContext() {
      this.$root.$orderSuccessPopupContext.value = null
    },
    onClickGoToOrders() {
      this.clearContext()
      this.$router.push('/orders')
    },
    onClickGoToOrder(orderId) {
      this.clearContext()
      this.$router.push(`/order/${orderId}`)
    },
    onClickGoToCheckout() {
      this.clearContext()
      if (this.isBrand) {
        this.$router.go(-1)
        return
      }
      this.$router.push('/checkout')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/components/_variables.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';

.modal-body-text {
  font-size: 24px;
  font-weight: 600;
}

.order-ids-container {
  margin-top: 20px;
  max-height: 350px;
  overflow-y: scroll;
  padding-right: 10px;

  .order-id-item {
    background-color: #DFDFDF;
    height: 88px;
    border-left: 4px solid;
    margin-bottom: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;

    .item-text {
      text-align: left;
      color: $text-color-v3;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      word-wrap: break-word
    }

    .skeleton-item {
      height: 14px;
      background: $light-silver;
    }
  }
}
</style>
