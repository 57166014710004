<template>
  <div class="confetti">
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />
    <div class="confetti-piece" />

    <slot />
  </div>
</template>

<script>
export default {
    name: 'ConfettiAnimation',
}
</script>

<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

$duration: 800;

@function randomNum($min, $max) {
    $rand: random();
    $randomNum: $min + floor($rand * (($max - $min) + 1));

    @return $randomNum;
}

.icon {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 32px;
    position: relative;
}

.confetti {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.confetti-piece {
    position: absolute;
    width: 8px;
    height: 16px;
    background: $yellow;
    top: 0;
    opacity: 0;

    @for $i from 1 through 40 {
        &:nth-child(#{$i}) {
            left: $i * 7%;
            transform: rotate(#{randomNum(-80, 80)}deg);
            animation: makeItRain $duration * 1ms infinite ease-out;
            animation-delay: #{randomNum(0, $duration * .5)}ms;
            animation-duration: #{randomNum($duration * .7, $duration * 1.2)}ms
        }
    }

    &:nth-child(odd) {
        background: $blue;
    }

    &:nth-child(even) {
        z-index: 1;
    }

    &:nth-child(4n) {
        width: 5px;
        height: 12px;
        animation-duration: $duration * 2ms;
    }

    &:nth-child(3n) {
        width: 3px;
        height: 10px;
        animation-duration: $duration * 2.5ms;
        animation-delay: $duration * 1ms;
    }

    &:nth-child(4n-7) {
        background: $pink;
    }
}

@keyframes makeItRain {
    from {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    to {
        transform: translateY(200px);
    }
}
</style>
